import React from 'react';
import Sidebar from '../../components/Navigation/Sidebar';
import Topbar from '../../components/Navigation/Topbar';
import { Footer } from '../../components/Footer';
import Home from '../../components/Home/Home';
import { Route, Switch } from 'react-router-dom';
import Transactions from '../Transactions/Transactions';
import { Miners } from '../Miners';
import { Accounts } from '../Accounts';
import { Coins } from '../Coins';
import { NFTs } from '../NFTs';
import Framework from '../Framework/Framework';
import MinerDetails from '../Miners/MinersDetails/MinersDetails';
import { clickMenuOpen } from '../../redux/actions/index'
import { Blocks } from '../Blocks';

const menuItems = [
    {
        name: 'Home',
        path: '/',
        component: Home,

    },
    {
        name: 'Transactions',
        path: '/transactions',
        component: Transactions
    },
    {
        name: 'Miners',
        path: '/miners',
        component: Miners
    },
    {
        name: 'Accounts',
        path: '/accounts',
        component: Accounts
    },
    {
        name: 'Coins',
        path: '/coins',
        component: Coins
    },
    {
        name: 'NFTs',
        path: '/nfts',
        component: NFTs
    },
    {
        name: 'Framework',
        path: '/framework',
        component: Framework
    },
    {
        name: 'Blocks',
        path: '/works',
        component: Blocks
    },
    {
        name: 'MinerDetails',
        path: '/miners/:miner',
        component: MinerDetails
    },
]

function Dashboard() {

    return (
        <>
            {/* <!-- Page Wrapper --> */}
            <div id="wrapper" className='d-flex'>
                {/* <!-- Sidebar --> */}
                <Sidebar />
                {/* <!-- End of Sidebar --> */}

                {/* <!-- Content Wrapper --> */}
                <div className="main-content">
                    <div id="content" className="bg-grey-light ">
                        {/* <Home /> */}
                        <div className="dashboard-mid">
                        </div>
                        <Topbar clickMenuOpen={clickMenuOpen} />

                        <Switch>
                            {
                                menuItems.map(item => (
                                    <Route exact path={item.path} component={item.component} key={item.name} />
                                ))
                            }
                            <Route path='*' component={Home} />
                        </Switch>
                    </div>
                    <Footer />
                </div>
                {/* <!-- End of Content Wrapper --> */}
            </div>
        </>
    )
}
export default Dashboard;