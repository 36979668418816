import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";


function Blocks() {
    
    const [blocks, setBlocks] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const [pageCount, setPageCount] = useState(0);

    const handleWork = async () => {
        try {
            const res = await fetch(`https://mining-pool.mobitscan.com/work`)
            if (!res.ok) {
                throw new Error("Can not fetch")
            }
            const jsonData = await res.json();
            // console.log(jsonData.data);
            setBlocks(jsonData.data.slice((currentPage - 1) * postsPerPage, ((currentPage - 1) * postsPerPage) + postsPerPage));
            setPageCount(Math.ceil(jsonData.data.length / postsPerPage));

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        handleWork();
    }, [currentPage, postsPerPage]);

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setCurrentPage(selectedPage + 1);
    };

    return (
        <div className="container-fluid">
            <h2 className="fw-600 text-black mode-light" style={{ fontSize: 40 }}>Blocks</h2>
            <div className="bg-white p-3 round-8 mt-4 bg-dark-mode">
                <table className="table mb-0">
                    <tbody>
                        <tr className="fs-12">
                            <td className="min-70">Blocks #</td>
                            <td className="min-80">Status</td>
                            <td>Reward</td>
                            <td>Difficulty</td>
                            <td style={{ width: '25%' }}>Created At</td>
                            <td style={{ width: '25%' }}>Update At</td>
                        </tr>
                        {blocks.map((block, index) => {
                            return (
                                <tr key={index} className="fs-14">
                                    <td className="text-green-dark hovgreen">{block.index}</td>
                                    <td className="text-black">{block.status}</td>
                                    <td className="text-black">{block.reward}</td>
                                    <td className="text-black">{block.difficulty}</td>
                                    <td className="text-black">{block.createdAt}</td>
                                    <td className="text-black">{block.updatedAt}</td>
                                </tr>
                            )
                        })}

                    </tbody>
                </table>
                <div className="css-bgmiu4 mt-2" style={{ justifyContent: "flex-end" }}>
                    <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"} />
                </div>
            </div>
        </div>

    );
}


export default Blocks;