import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Chart from '../../components/Chart/Chart';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function Home() {

    const [renderMiners, setRenderMiners] = useState([]);
    const [totalMiner, setTotalMiner] = useState([]);
    const [loading, setLoading] = useState(false);

    const [totalWork, setTotalWork] = useState([]);

    const handleActiveMiner = async () => {
        try {
            setLoading(true);
            const res = await fetch(`https://pool-api.fiscan.io/miner`)
            if (!res.ok) {
                throw new Error("Can not fetch")
            }
            const jsonData = await res.json();
            convertDataMiner(Object.entries(jsonData));
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }

    }

    useEffect(() => {
        handleActiveMiner();
    }, [])

    const convertDataMiner = (data) => {
        let array = [];
        for (let i = 0; i < data.length; i++) {
            const item = {};
            item["miner"] = data[i][0];
            item["hashrate"] = data[i][1];

            array.push(item);
        }
        setTotalMiner(array);

        const newData = array.slice(0, 5);
        setRenderMiners(newData.filter(item => item.miner !== '0xaaaaaaaaaaaaaaaaaaaaaaa'));
    }

    const handleActiveWorker = async () => {
        try {
            setLoading(true);
            const res = await fetch(`https://pool-api.fiscan.io/getwork`)
            if (!res.ok) {
                throw new Error("Can not fetch")
            }
            const jsonData = await res.json();
            convertDataWorker(Object.entries(jsonData));
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }

    }


    // convert dataWorker
    const convertDataWorker = (data) => {
        let array = [];
        for (let i = 0; i < data.length; i++) {
            const item = {};
            item["miner"] = data[i][0];
            item["hashrate"] = data[i][1];

            array.push(item);
        }
        setTotalWork(array);
    }

    useEffect(() => {
        handleActiveWorker();
    }, [])


    return (
        <div className="container-fluid">
            <div className="tab-content" id="v-pills-tabContent">
                <div className="tab-pane fade show active pt-3" id="v-pills-home"
                    aria-labelledby="v-pills-home-tab">
                    <h2 className="fw-600 text-black mode-light fichain-exlorer">Fichain Explorer</h2>
                    <p className="fs-14">A blockchain explorer and analytics platform for Fichain Mainnet.</p>
                    <div className="bg-white p-3 round-8 mb-4 bg-dark-mode">
                        <div className="row">
                            <div className="col-md-3 border-right">
                                <p className="fs-12 mb-2">
                                    <i
                                        className="fa-regular fa-circle text-green-dark bg-grey-light p-2 rounded-circle bg-muted"></i>
                                    <span className="fw-600 ml-2 text-muted-dark">FIB Price</span>
                                </p>
                                <h5 className="text-black fw-600 mb-0 ml-2">$5.19</h5>
                            </div>
                            <div className="col-md-3 border-right">
                                <p className="fs-12 mb-2">
                                    <i
                                        className="fa-solid fa-cube text-green-dark bg-grey-light p-2 rounded-circle bg-muted"></i>
                                    <span className="fw-600 ml-2 text-muted-dark">FIB Market Cap</span>
                                </p>
                                <h5 className="text-black fw-600 mb-0 ml-2">$8.19</h5>
                            </div>
                            <div className="col-md-3 border-right">
                                <p className="fs-12 mb-2">
                                    <i
                                        className="fa-regular fa-file text-green-dark bg-grey-light  p-2 rounded-circle bg-muted"></i>
                                    <span className="fw-600 ml-2 text-muted-dark">Total Works</span>
                                </p>
                                <h5 className="text-black fw-600 mb-0 ml-2">{totalWork.length}</h5>
                            </div>
                            <div className="col-md-3">
                                <p className="fs-12 mb-2">
                                    <i
                                        className="fa-solid fa-book text-green-dark bg-grey-light p-2 rounded-circle bg-muted"></i>
                                    <span className="fw-600 ml-2 text-muted-dark">Total Miners</span>
                                </p>
                                <h5 className="text-black fw-600 mb-0 ml-2">{totalMiner.length}</h5>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="p-3 round-8 bg-white bg-dark-mode">
                                <h6 className="fs-12 fw-600">Fichain Transaction History Last 14 Days</h6>
                                <div id="chartContainer"><Chart /></div>
                            </div>
                        </div>

                        <div className="col-md-6 slide">
                            <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img src="/img/bg1.png" style={{ borderRadius: 10 }} className="d-block h-100 w-100" alt="..." />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="/img/bg4.png" style={{ borderRadius: 10 }} className="d-block h-100 w-100" alt="..." />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="/img/bg3.png" style={{ borderRadius: 10 }} className="d-block h-100 w-100" alt="..." />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                    <div className="row mt-4">
                        <div className="col-md-6">
                            <div className="bg-white p-3 round-8 bg-dark-mode overflow-auto">
                                <h6 className="text-black">The latest 5 Miners</h6>
                                {loading
                                    ?
                                    (
                                        <div className="css-miner-noData">
                                            <div className="css-noData-form">
                                                <Box sx={{ display: 'flex' }}>
                                                    <CircularProgress color="inherit" />
                                                </Box>
                                                <div className="css-noData-text">No Data</div>
                                                <div className="css-noDataAvai-text">No data available</div>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <table className="table mb-0">
                                                <tbody>
                                                    <tr className="fs-12">
                                                        <td className="min-80">Miner</td>
                                                        <td className="min-70">Hashrate</td>
                                                    </tr>
                                                    {renderMiners && renderMiners.map((item, index) => {
                                                        return (
                                                            <tr key={index} className="fs-14">
                                                                <td>
                                                                    <Link to={`/miners/${item.miner}`} className="text-green-dark hovgreen"> {`${item.miner.substring(
                                                                        0,
                                                                        4
                                                                    )}...${item.miner.slice(-8)}`}</Link>
                                                                </td>
                                                                <td className="text-black">{item.hashrate} Mh/s</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                            <p className="text-center fs-12 mb-0">
                                                <Link to="/miners" className="text-secondary">
                                                    View All Miners
                                                </Link>
                                            </p>
                                        </>
                                    )}
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="bg-white p-3 round-8 bg-dark-mode overflow-auto">
                                <h6 className="text-black">The latest 5 Miners</h6>
                                {loading ? (
                                    <div className="css-miner-noData">
                                        <div className="css-noData-form">
                                            <Box sx={{ display: 'flex' }}>
                                                <CircularProgress color="inherit" />
                                            </Box>
                                            <div className="css-noData-text">No Data</div>
                                            <div className="css-noDataAvai-text">No data available</div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <table className="table mb-0">
                                            <tbody>
                                                <tr className="fs-12">
                                                    <td className="min-80">Miner</td>
                                                    <td className="min-70">Hashrate</td>
                                                </tr>
                                                {renderMiners && renderMiners.map((item, index) => {
                                                    return (
                                                        <tr key={index} className="fs-14">
                                                            <td>
                                                                <Link to={`/miners/${item.miner}`} className="text-green-dark hovgreen"> {`${item.miner.substring(
                                                                    0,
                                                                    4
                                                                )}...${item.miner.slice(-8)}`}</Link>
                                                            </td>
                                                            <td className="text-black">{item.hashrate} Mh/s</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                        <p className="text-center fs-12 mb-0">
                                            <Link to="/miners" className="text-secondary">
                                                View All Miners
                                            </Link>
                                        </p>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="bg-white p-3 round-8 mt-4 bg-dark-mode">
                        <h6 className="text-black">Current Validators</h6>
                        <table className="table mb-0">
                            <tbody>
                                <tr className="fs-12">
                                    <td>Blocks</td>
                                    <td>Status</td>
                                    <td>Reward</td>
                                    <td>Difficulty</td>
                                </tr>
                            </tbody>
                        </table>
                        <p className="text-center fs-12 mb-0"><Link to="/works" className="text-secondary">View All Validators</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;