import { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import * as React from 'react';

function MinerDetails() {

    const { miner } = useParams();


    const [loading, setLoading] = useState(false);
    const [renderMiner, setRenderMiner] = useState([]);

    const [sumHashrate, setSumHashrate] = useState(0);


    //paginate
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(5);
    const [pageCount, setPageCount] = useState(0);


    //  copy
    const [text] = useState(miner);
    const [show, setShow] = useState(false);
    const target = useRef(null);

    const handleCopy = async () => {
        await navigator.clipboard.writeText(text)

    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(false);
        }, 1000);
        return () => clearTimeout(timer);
    }, [show]);

    const handleMinerDetail = async () => {
        try {
            setLoading(true);
            const res = await fetch(`https://pool-api.fiscan.io/miner/${miner}`)
            if (!res.ok) {
                throw new Error("Can not fetch")
            }
            const jsonData = await res.json();
            convertData(jsonData, miner);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    // convert data api
    const convertData = (data, miner) => {
        let array = [];
        for (let i = 0; i < data.length; i++) {
            const item = {};
            item["worker"] = data[i][0].split(`miner=${miner}&name=`)[1];
            item["hashrate"] = data[i][1];
            array.push(item);
        }
        setRenderMiner(array.slice((currentPage - 1) * postsPerPage, ((currentPage - 1) * postsPerPage) + postsPerPage));
        setPageCount(Math.ceil(array.length / postsPerPage));

        const total = array.reduce((total, item) => Number(total) + Number(item.hashrate), 0);
        setSumHashrate(total.toFixed(2));
    }

    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1);
    };

    // setRenderMiner1(renderMiner.sort((a, b) => (a.hashrate > b.hashrate) ? 1 : -1));

    useEffect(() => {
        handleMinerDetail();

    }, [miner, currentPage, postsPerPage,pageCount]);


    console.log(currentPage);

    // useEffect(() => {
    //     const handleMiner = async () => {
    //         await fetch(`https://mining-pool.mobitscan.com/miner/${_id}`)
    //             .then((response) => response.json())
    //             .then((data) => {
    //                 setWorksDetail(data.works.slice((currentPage - 1) * postsPerPage, ((currentPage - 1) * postsPerPage) + postsPerPage));
    //                 updateMinerDetail(data.works)
    //                 setMinersDetail(data);
    //                 setText(data.address);
    //                 setPageCount(Math.ceil(data.works.length / postsPerPage));
    //             })
    //             .catch((error) => console.log(error));
    //     };

    //     handleMiner();
    // }, [_id, currentPage, postsPerPage, currentPage1, postsPerPage1]);

    return (
        <div className="container-fluid">
            <div className="css-minerDetail">
                <div className="css-minerDetail-back">
                    <Link to='/miners'>
                        <svg viewBox="0 0 40 40" focusable="false" className="chakra-icon css-minerDetail-backIcon mode-light"><path fillRule="evenodd" clipRule="evenodd" d="M24.5118 8.82147C25.1627 9.47234 25.1627 10.5276 24.5118 11.1785L15.6904 20L24.5118 28.8215C25.1627 29.4723 25.1627 30.5276 24.5118 31.1785C23.861 31.8294 22.8057 31.8294 22.1548 31.1785L12.1548 21.1785C11.5039 20.5276 11.5039 19.4723 12.1548 18.8215L22.1548 8.82147C22.8057 8.17059 23.861 8.17059 24.5118 8.82147Z"></path></svg>
                    </Link>
                </div>
                <h2 className="fw-600 text-black mode-light css-minerDetail-text ">Miner details</h2>
            </div>

            <div className="css-minerDetail-header">
                <div className="text-black mode-light">{miner}</div>

                <div className="css-minerDetail-copy" ref={target} onClick={() => setShow(!show)}><svg onClick={handleCopy} disabled={!text} viewBox="0 0 40 40" focusable="false" className="chakra-icon css-copyIcon mode-light"><path fillRule="evenodd" clipRule="evenodd" d="M13.3333 8.33333C13.3333 6.49238 14.8257 5 16.6666 5H29.1666C31.0076 5 32.5 6.49238 32.5 8.33333V24.1667C32.5 26.0076 31.0076 27.5 29.1666 27.5H16.6666C14.8257 27.5 13.3333 26.0076 13.3333 24.1667V8.33333ZM29.1666 8.33333L16.6666 8.33333V24.1667H29.1666V8.33333Z"></path><path fillRule="evenodd" clipRule="evenodd" d="M8.33332 14.1665C9.2538 14.1665 9.99999 14.9127 9.99999 15.8332V29.1665C9.99999 30.087 10.7462 30.8332 11.6667 30.8332H24.1667C25.0871 30.8332 25.8333 31.5794 25.8333 32.4998C25.8333 33.4203 25.0871 34.1665 24.1667 34.1665H11.6667C8.90523 34.1665 6.66666 31.9279 6.66666 29.1665V15.8332C6.66666 14.9127 7.41285 14.1665 8.33332 14.1665Z"></path></svg>
                    <Overlay target={target.current} show={show} placement="right" offset={[0, 5]}>
                        {(props) => (
                            <Tooltip id="overlay-example" {...props}>
                                Copied
                            </Tooltip>
                        )}
                    </Overlay>
                </div>
            </div>

            <div className="css-minerDetail-header">
                <div className="text-black mode-light ">Total hashrate :</div>
                <div className="css-minerDetail-header-text fw-600 text-black mode-light p-2" style={{ fontSize: 20 }}>{sumHashrate} Mh/s</div>
            </div>
            <div className="bg-white p-4 round-8 mt-4 mb-4 bg-dark-mode">
                <div className="css-minerDetail-tableText fw-600 mode-light">
                    <nav className="css-minerDetail-navText">Overview</nav>
                </div>
                <div className="css-minerDetail-tableText mode-light mt-3" >
                    <div className="css-minerDetail-navText">A total of worker</div>
                </div>

                {loading ? (
                    <div className="css-miner-noData">
                        <div className="css-noData-form">
                            <Box sx={{ display: 'flex' }}>
                                <CircularProgress color="inherit" />
                            </Box>
                            <div className="css-noData-text">No Data</div>
                            <div className="css-noDataAvai-text">No data available</div>
                        </div>
                    </div>
                )
                    :
                    (
                        <>
                            <div className="bg-white p-3 round-8 bg-dark-mode">
                                <table className="table mb-0">
                                    <tbody>
                                        <tr className="fs-12">
                                            <td style={{ width: '40%' }} >Worker name</td>
                                            <td >Hashrate (current)</td>
                                        </tr>
                                        {renderMiner.map((item, index) => {
                                            return (
                                                <tr key={index} className="fs-14">
                                                    <td className="text-black">{item.worker}</td>
                                                    <td className="text-black">{item.hashrate} Mh/s</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="css-pagination">
                                <span></span>
                                <ReactPaginate
                                    previousLabel={"<"}
                                    nextLabel={"Next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"} />
                            </div>
                        </>
                    )
                }
            </div>
        </div >
    );
}
export default MinerDetails;