import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
function Miners() {


    const [minerActive, setMinerActive] = useState([]);
    const [loading, setLoading] = useState(false);

    // const handleMiner = async () => {
    //     try {
    //         setLoading(true);
    //         const response = await fetch(`https://mining-pool.mobitscan.com/miner`)
    //         if (!response.ok) {
    //             throw new Error("Can not fetch")
    //         }
    //         const jsonData = await response.json();
    //         setMinerActive(jsonData.filter(item => item.active.status === true));
    //         setMinerInActive(jsonData.filter(item => item.active.status === false));
    //     } catch (err) {
    //         console.log(err);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    // useEffect(() => {
    //     handleMiner();
    // }, []);


    const handleActiveMiner = async () => {
        try {
            setLoading(true);
            const res = await fetch(`https://pool-api.fiscan.io/miner`)
            if (!res.ok) {
                throw new Error("Can not fetch")
            }
            const jsonData = await res.json();

            convertData(Object.entries(jsonData));

        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }

    }


    // convert dataMiner
    const convertData = (data) => {
        let array = [];
        for (let i = 0; i < data.length; i++) {
            const item = {};
            item["miner"] = data[i][0];
            item["hashrate"] = data[i][1];

            array.push(item);
        }

        setMinerActive(array.filter(item => item.miner !== '0xaaaaaaaaaaaaaaaaaaaaaaa'));
    }


    useEffect(() => {
        handleActiveMiner();
    }, [])

    // const updateMinerDetail = (data) => {
    //     const a = data.filter(item => item.active.status === true);
    //     console.log(a);
    //     for (let i = 0; i < data.length; i++) {
    //         const miner = data[i];
    //         if (miner.active.status === true) {
    //             console.log(miner);
    //         }
    //     }
    // }


    return (
        <div className="container-fluid">
            <h2 className="fw-600 text-black mode-light" style={{ fontSize: 40, marginBottom: '32px' }}>Miner Lists</h2>
            <div className="bg-white p-4 round-8 mt-4 mb-4 bg-dark-mode">
                {loading ?
                    (
                        <div className="css-miner-noData">
                            <div className="css-noData-form">
                                <Box sx={{ display: 'flex' }}>
                                    <CircularProgress color="inherit" />
                                </Box>
                                <div className="css-noData-text">No Data</div>
                                <div className="css-noDataAvai-text">No data available</div>
                            </div>
                        </div>

                    ) :
                    (
                        <>
                            <div className="bg-white p-3 round-8 bg-dark-mode">
                                <div className="mode-light">
                                    <div className="mb-3">Active Miners <i style={{ color: "#02c076" }} className="fa-solid fa-circle-check"></i></div>
                                </div >
                                <table className="table mb-0">
                                    <tbody>
                                        <tr className="fs-12">
                                            <td style={{ width: '50%' }} className="min-70">Miners</td>
                                            <td className="min-80">Total hashrate</td>
                                        </tr>
                                        {minerActive && minerActive.map((item, index) => {
                                            return (
                                                <tr key={index} className="fs-14">
                                                    <td>
                                                        <Link to={`/miners/${item.miner}`} className="text-green-dark hovgreen">{item.miner}</Link>
                                                    </td>
                                                    <td className="text-black">{item.hashrate} Mh/s</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>

                            {/* <div className="bg-white p-3 round-8 bg-dark-mode">

                            <div className="css-1pdjxnf mode-light mt-3" > <div className="css-1u0vlcl">Inactive Miners</div></div >

                            <table className="table mb-0 ">
                                <tbody>
                                    <tr className="fs-12">
                                        <td style={{ width: '30%' }} className="min-70">Miners</td>
                                        <td className="min-80">Status</td>
                                        <td style={{ width: '40%' }} className="min-80">Age</td>
                                    </tr>

                                    {minerInActive.map((miner, index) => {
                                        return (
                                            <tr data={miner} key={index} className="fs-14">
                                                <td><Link to={`/miners/${miner._id}`} className="text-green-dark hovgreen">
                                                    {`${miner.address.substring(
                                                        0,
                                                        4
                                                    )}...${miner.address.slice(-8)}`}
                                                </Link>
                                                </td>
                                                <td className="text-black"><i style={{ color: "#d9304e" }} className="fa-solid fa-circle-xmark"></i> {miner.active.status ? "active" : "not active"}</td>
                                                <td className="text-black">{miner.active.activeFrom ? miner.active.activeFrom : "no data"}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div> */}
                        </>
                    )
                }
            </div>
        </div>
    );
}


export default Miners;