import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React from "react";

function Chart() {
  const options = {
    chart: {
      type: "spline",
      backgroundColor: "#fff",
      height: 250,
    },
    plotOptions: {
      spline: {
        lineWidth: 2,
        lineColor: "#2AA372",
        states: {
          hover: {
            lineWidth: 5,
          },
        },
        marker: {
          enabled: true,
          fillColor: '#2AA372',
        },
      },
    },
    title: {
      text: "  ",
    },
    accessibility: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    yAxis: {
      title: {
        text: "",

      },
      labels: {
        formatter: function () {
          return "$" + Math.abs(this.value) / 1;
        },
      },
    },
    series: [
      {
        name: "",
        data: [
          { label: new Date(2017, 0), y: 46000 },

          { label: new Date(2017, 1), y: 45850 },

          { label: new Date(2017, 2), y: 45500 },

          { label: new Date(2017, 3), y: 46000 },

          { label: new Date(2017, 4), y: 46000 },

          { label: new Date(2017, 5), y: 49500 },

          { label: new Date(2017, 6), y: 46550 },

          { label: new Date(2017, 7), y: 46202 },

          { label: new Date(2017, 8), y: 46003 },

          { label: new Date(2017, 9), y: 46301 },

          { label: new Date(2017, 10), y: 46001 },

          { label: new Date(2017, 11), y: 46501 },
        ],

      },
    ],
  };
  return (
    <div className="chart-table" style={{ width: "100%", height: "276px" }}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}

export default Chart;


