import React from "react";
import { Link } from "react-router-dom";


function Transactions() {

    return (
        <div className="container-fluid">
            <h2 className="fw-600 text-black mode-light" style={{ fontSize: 40 }}>Transactions</h2>
            <div className="bg-white p-4 round-8 mt-4 mb-4">
                <table className="table mb-0">
                    <tbody>
                        <tr className="fs-12">
                            <td>Version #</td>
                            <td className="min-70">Block</td>
                            <td className="min-80">Age</td>
                            <td>Sender</td>
                            <td>Receiver</td>
                            <td>Function</td>
                            <td>Txn Fee</td>
                            <td>Amount</td>
                        </tr>
                        <tr className="fs-14">
                            <td><Link href="#" className="text-green-dark hovgreen">25842230</Link></td>
                            <td><Link href="#" className="text-green-dark hovgreen">32816242</Link>
                            </td>
                            <td className="text-black">5s ago</td>
                            <td><Link href="#" className="text-green-dark hovgreen">0x94...3b5e094d</Link>
                            </td>
                            <td><Link href="#" className="text-green-dark hovgreen">0xc7...52f45c4b</Link>
                            </td>
                            <td>piec..._price_2</td>
                            <td>0.000542 APT</td>
                            <td>0 APT</td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>

    );
}


export default Transactions;