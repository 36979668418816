import React from "react";
import { BrowserRouter as Router } from "react-router-dom";


//Pages
// import SignUp from "./pages/SignUp";
// import SignIn from "./pages/SignIn";
import Dashboard from "./pages/Dashboard/Dashboard";
// import Transactions from "./pages/Transactions/Transactions";
// import NotFound from "./pages/NotFound";


const Routes = () => (

    <Router>
        <Dashboard />
    </Router>

);

export default Routes;
