import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clickMenuOpen } from '../../../redux/actions';

const Sidebar = ({ clickMenuOpen, toggled }) => {

    const location = useLocation();

    // const [currentPath, setCurrentPath] = useState();
    // useEffect(() => {
    //     setCurrentPath(location.pathname);
    // }, [location]);



    return (
        // {toggled ? "navbar-nav sidebar sidebar-dark accordion mx-3 toggled" : "navbar-nav sidebar sidebar-dark accordion mx-3"}
        <div className="navbar-nav sidebar sidebar-dark">
            <Link className="sidebar-brand d-flex align-items-center p-0" to='/'>
                <div className="sidebar-brand-icon mr-2">
                    <img src='/img/logo1.png' alt="" width="30px" />
                </div>
                <div className="text-capitalize mt-2">
                    <p className="sidebar-brand-text lh-0">Fichain</p>
                    <p className="mr-2 mb-0 fs-mini text-muted lh-0 powtext mb-2" style={{ fontSize: 10 }}>www.fireal.io</p>
                </div>
            </Link>

            <div className="dropdown border mt-3 mb-2 border-dark-mode">
                <button className="btn dropdown-toggle w-100 text-black online position-relative py-3 aptos d-flex justify-content-center" type="button"
                    id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                    <p className="mb-0">Fichain Mainnet</p>
                </button>
                <ul className="dropdown-menu w-100 bg-dark-mode" aria-labelledby="dropdownMenuButton">
                    <li><Link className="dropdown-item mode-light" to='/'>Fichain Mainnet</Link></li>
                    <li><Link className="dropdown-item mode-light" to='/' >Fichain Testnet</Link></li>
                </ul>
            </div>
            <div className="leftbar">
                <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <Link to='/' className={location.pathname === "/" ? "nav-link active" : "nav-link"} >
                        <nav className='css-nav-sidebar'>
                            <svg viewBox="0 0 40 40" focusable="false" className="chakra-icon css-sidebarIcon"><path fillRule="evenodd" clipRule="evenodd" d="M20.5473 8.5202C20.2336 8.24696 19.7664 8.24696 19.4526 8.5202L9.03597 17.5928C8.85427 17.751 8.74996 17.9802 8.74996 18.2212V30.8333C8.74996 31.2935 9.12305 31.6666 9.58329 31.6666H30.4166C30.8769 31.6666 31.25 31.2935 31.25 30.8333V18.2212C31.25 17.9802 31.1457 17.751 30.9639 17.5928L20.5473 8.5202ZM17.2634 6.00659C18.832 4.64038 21.1679 4.64038 22.7365 6.00659L33.1532 15.0792C34.0618 15.8705 34.5833 17.0163 34.5833 18.2212V30.8333C34.5833 33.1345 32.7178 35 30.4166 35H9.58329C7.28211 35 5.41663 33.1345 5.41663 30.8333V18.2212C5.41663 17.0163 5.93816 15.8705 6.84671 15.0792L17.2634 6.00659Z"></path></svg>
                            <span className='css-sidebar-text'>Home</span>
                        </nav>
                    </Link>
                    {/* <Link to='/transactions' className={location.pathname === "/transactions" ? "nav-link active" : "nav-link"}>
                        <span><i className="fa-solid fa-file"></i></span>
                        <span>Transactions</span>
                    </Link> */}
                    <Link to='/miners' className={location.pathname === "/miners" ? "nav-link active" : "nav-link"}>
                        <nav className='css-nav-sidebar'>
                            <svg viewBox="0 0 40 40" focusable="false" className="chakra-icon css-sidebarIcon"><path fillRule="evenodd" clipRule="evenodd" d="M17.9166 4.99996C18.835 4.55469 21.1649 4.5547 22.0833 4.99997L32.0833 9.99998C34.1666 11 35.4166 13.3333 35.4166 15.8333V26.6666C35.4166 28.3333 34.1666 29.6666 32.5 30.5L22.0833 35C21.2513 35.3529 18.7486 35.3529 17.9166 35L7.49998 30.5C5.83331 29.5833 4.58331 28.3333 4.58331 26.6666V15.8333C4.58331 13.3333 5.83331 11 7.91665 9.99998L17.9166 4.99996ZM20 7.68556L9.92789 12.569L20 16.9336L30.0721 12.569L20 7.68556ZM32.0833 15.3303L21.6666 19.8442V31.6491L32.0833 27.2299V15.3303ZM18.3333 31.6491V19.8442L7.91665 15.3303V27.2299L18.3333 31.6491Z"></path></svg>
                            <span className='css-sidebar-text'>Miners</span>
                        </nav>
                    </Link>

                    {/* <Link to='/accounts' className={location.pathname === "/accounts" ? "nav-link active" : "nav-link"}>
                        <span><i className="fa-solid fa-user"></i></span>
                        <span>Accounts</span>
                    </Link>
                    <Link to='/coins' className={location.pathname === "/coins" ? "nav-link active" : "nav-link"}>
                        <span><i className="fa-solid fa-coins"></i></span>
                        <span>Coins</span>
                    </Link>
                    <Link to='/nfts' className={location.pathname === "/nfts" ? "nav-link active" : "nav-link"}>
                        <span><i className="fa-solid fa-n"></i></span>
                        <span>NFTs</span>
                    </Link>
                    <Link to='/framework' className={location.pathname === "/framework" ? "nav-link active" : "nav-link"}>
                        <span><i className="fa-solid fa-shield"></i></span>
                        <span>Framework</span>
                    </Link>
                    <Link to='/validators' className={location.pathname === "/validators" ? "nav-link active" : "nav-link"}>
                        <span><i className="fa-solid fa-user"></i></span>
                        <span>Validators</span>
                    </Link> */}

                    {/*
                    {menuItems.map((item,index) => {
                        return (
                            <Link key={index} to={item.path} className={location.pathname === item.path ? "nav-link active" : "nav-link"}>
                                <span><i className="fa-solid fa-user"></i></span>
                                <span>{item.name}</span>
                            </Link>
                        )
                    })} */}
                </div>
                <div className="sidebar-mid" />
            </div>
            <span
                style={{
                    boxSizing: "border-box",
                    display: "inline-block",
                    overflow: "hidden",
                    width: "initial",
                    height: "initial",
                    background: "none",
                    opacity: 1,
                    border: 0,
                    marginBottom: '20px',
                    padding: 0,
                    position: "relative",
                    maxWidth: "100%",
                }}
            >
                <span
                    style={{
                        boxSizing: "border-box",
                        display: "block",
                        width: "initial",
                        height: "initial",
                        background: "none",
                        opacity: 1,
                        border: 0,
                        margin: 0,
                        padding: 0,
                        maxWidth: "100%"
                    }}
                >
                    <img
                        alt=""
                        aria-hidden="true"
                        src="/img/feedback_card.png"
                        style={{
                            display: "block",
                            maxWidth: "100%",
                            width: "initial",
                            height: "initial",
                            background: "none",
                            opacity: 1,
                            border: 0,
                            margin: 0,
                            padding: 0
                        }}
                    />
                </span>
                <img
                    alt=""
                    src="/_next/image?url=https%3A%2F%2Fwww.nodereal.io%2Fstatic%2Faptos-trace%2Fimages%2Ffeedback_card.png&w=384&q=75"
                    decoding="async"
                    data-nimg="intrinsic"
                    srcSet="/_next/image?url=https%3A%2F%2Fwww.nodereal.io%2Fstatic%2Faptos-trace%2Fimages%2Ffeedback_card.png&w=256&q=75 1x, /_next/image?url=https%3A%2F%2Fwww.nodereal.io%2Fstatic%2Faptos-trace%2Fimages%2Ffeedback_card.png&w=384&q=75 2x"
                    style={{
                        position: "absolute",
                        inset: 0,
                        boxSizing: "border-box",
                        padding: 0,
                        border: "none",
                        margin: "auto",
                        display: "block",
                        width: 0,
                        height: 0,
                        minWidth: "100%",
                        maxWidth: "100%",
                        minHeight: "100%",
                        maxHeight: "100%"
                    }}
                />
                <noscript />
            </span>

            {/* <hr className="sidebar-divider d-none d-md-block" /> */}
            {/*
            <div className="text-center d-none d-md-inline">
                <button onClick={() => { clickMenuOpen() }} className="rounded-circle border-0" id="sidebarToggle"></button>
            </div> */}
        </div>
    )
}

const mapDispatchToProps = dispatch =>
    bindActionCreators({ clickMenuOpen }, dispatch);

const mapStateToProps = store => ({
    toggled: store.menuState.menuOpen
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);