import React from "react";
import Routes from "./routes";

// const App = () => <Routes />;


function App() {

    return (
        <div>
            <Routes />
        </div>
    )
}



export default App;
